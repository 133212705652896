<script setup>
const props = defineProps({
  data: String,
});

const formatted = computed(() =>
  !props.data
    ? ""
    : new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(Date.parse(props.data.slice(0, -3)))
);
</script>

<template>
  <p class="date" v-if="data" v-html="formatted" />
</template>

<style lang="postcss"></style>
